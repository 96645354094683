<template>
  <v-container id="locationHours" fluid tag="section" class="ma-4 pa-8 white rounded-lg">
    <alert-notification :message="alert" />
    <div class="mb-2">
      <h1>{{ $t('admin.sub.businessHours.openingToday.title') }}</h1>
      <div v-if="openingHoursToday.length > 0">
        <div v-if="isClosed === true">
          <span class="red--text font-weight-bold font-italic">{{ $t('admin.sub.businessHours.openingToday.isClosed') }}</span>
        </div>
        <div v-else-if="isClosed === false">
          <span class="green--text font-weight-bold font-italic">{{ $t('admin.sub.businessHours.openingToday.isOpen') }}</span>
        </div>
        <div class="mt-1">
          {{ $t('admin.sub.businessHours.openingToday.opening') }}
        </div>
        <div v-for="(hours, i) in openingHoursToday" :key="i">
          {{ hours.openTime }} {{ $t('common.time.at') }} {{ hours.closeTime }}
        </div>
      </div>
      <div v-else>
        {{ $t('admin.sub.businessHours.openingToday.closeAllDay') }}
      </div>
    </div>
    <h1>{{ $t('admin.sub.businessHours.opening.title') }}</h1>
    <v-row v-for="hours in openingHours" :key="'opening' + hours.id" class="text-capitalize text-center">
      <v-col cols="6" lg="2">
        {{ displayWeekday(hours.weekday) }}
      </v-col>
      <v-col cols="6" lg="2">
        {{ displayTime(hours.openTime) }} - {{ displayTime(hours.closeTime) }}
      </v-col>
    </v-row>
    <br>
    <h1>{{ $t('admin.sub.businessHours.closing.title') }}</h1>
    <v-row v-for="closing in closingDates" :key="'closing' + closing.id" class="text-capitalize text-center">
      <v-col cols="2" lg="1">
        {{ $t('common.time.from') }}
      </v-col>
      <v-col cols="3" lg="2">
        {{ displayDate(closing.fromDate) }}
      </v-col>
      <v-col cols="2" lg="1">
        {{ $t('common.time.to') }}
      </v-col>
      <v-col cols="3" lg="2">
        {{ displayDate(closing.toDate) }}
      </v-col>
      <v-col cols="2" lg="1">
        <v-btn small color="senary" :loading="loading" :disabled="loading" @click.stop="deleteClosingDate(closing)">
          <v-icon left>
            mdi-delete
          </v-icon>{{ $t('admin.sub.businessHours.closing.button.delete') }}
        </v-btn>
      </v-col>
    </v-row>
    <h4>{{ $t('admin.sub.businessHours.closing.form.close.title') }}</h4>
    <period-selector :start-date-prop="startDate" :start-hour-prop="startHour" :end-date-prop="endDate" :end-hour-prop="endHour" :loading="loading" submit="manual" @update="createClosingDate" />
  </v-container>
</template>

<script>
  import { displayISO, getTodayDate, WEEKDAY } from '@/util/DateUtil';
  import { handleRequestError } from '@/services/common/Http';
  import AlertNotification from '@/views/components/common/notification/AlertNotification';
  import PeriodSelector from '@/views/components/common/PeriodSelector';

  export default {
    name: 'BusinessHours',
    components: { AlertNotification, PeriodSelector },
    data () {
      return {
        alert: '',
        loading: false,
        openingHours: [],
        openingHoursToday: [],
        closingDates: [],
        isClosed: undefined,
        startDate: getTodayDate(),
        startHour: '00:00',
        endDate: getTodayDate(),
        endHour: '23:59'
      };
    },
    mounted: async function () {
      await this.refreshOpeningHours();
      await this.refreshOpeningHoursToday();
      await this.refreshClosingDates();
      await this.refreshOpeningStatus();
    },
    methods: {
      displayWeekday (weekdayId) {
        if (WEEKDAY[weekdayId] === undefined) {
          return '?';
        }

        return WEEKDAY[weekdayId];
      },
      displayTime (time) {
        if (typeof time !== 'string') {
          return time;
        }

        const parts = time.split(':');
        if (parts < 2) {
          return time;
        }

        return parts[0] + 'h' + parts[1];
      },
      displayDate (date) {
        return displayISO(date, 'fr', false);
      },
      async refreshOpeningHours () {
        this.loading = true;
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'location/hours/business');
          if (response.data !== undefined && response.data.openingHours !== undefined) {
            this.openingHours = response.data.openingHours;
          }
          this.alert = '';
        } catch (error) {
          this.alert = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('admin.error.fail'));
        }
        this.loading = false;
      },
      async refreshOpeningHoursToday () {
        this.loading = true;
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'location/hours/business/today');
          if (response.data !== undefined && response.data.openingHoursToday !== undefined) {
            this.openingHoursToday = response.data.openingHoursToday;
          }
          this.alert = '';
        } catch (error) {
          this.alert = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('admin.error.fail'));
        }
        this.loading = false;
      },
      async refreshClosingDates () {
        this.loading = true;
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'location/hours/closing');
          if (response.data !== undefined && response.data.closingDates !== undefined) {
            this.closingDates = response.data.closingDates;
          }
          this.alert = '';
        } catch (error) {
          this.alert = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('admin.error.fail'));
        }
        this.loading = false;
      },
      async refreshOpeningStatus () {
        this.loading = true;
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'location/hours/closed');
          if (response.data !== undefined && response.data.isClosed !== undefined) {
            this.isClosed = response.data.isClosed;
          }
          this.alert = '';
        } catch (error) {
          this.alert = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('admin.error.fail'));
        }
        this.loading = false;
      },
      async createClosingDate (startDate, startHour, endDate, endHour, startFullDate, endFullDate) {
        this.loading = true;
        try {
          await this.$http.post(process.env.VUE_APP_BASE_API_URL + 'location/hours/closing', { from: startFullDate, to: endFullDate });
          this.alert = '';
        } catch (error) {
          this.alert = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('admin.error.fail'));
        }
        await this.refreshOpeningHoursToday();
        await this.refreshClosingDates();
        await this.refreshOpeningStatus();
        this.loading = false;
      },
      async deleteClosingDate (closingDate) {
        this.loading = true;
        try {
          await this.$http.delete(process.env.VUE_APP_BASE_API_URL + 'location/hours/closing?id=' + String(closingDate.id));
          this.alert = '';
        } catch (error) {
          this.alert = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('admin.error.fail'));
        }
        await this.refreshOpeningHoursToday();
        await this.refreshClosingDates();
        await this.refreshOpeningStatus();
        this.loading = false;
      },
      async fail (error, message) {
        this.alert = await handleRequestError(error, this.$router, this.$i18n, message);
      }
    }
  };
</script>
